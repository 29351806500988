import React from "react"

const SvgRefine = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="176"
        height="161"
        viewBox="0 0 176 161"
      >
        <path
          fill="#143554"
          stroke="#143554"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M20.94 130.25H155.06V147.56H20.94z"
        ></path>
        <g fill="none" stroke="#143554" strokeLinejoin="round" strokeWidth="2">
          <path
            strokeLinecap="round"
            d="M57.72 41.56s-28.13 6.49-15.15 47.59M57.72 41.56s28.12 6.49 15.14 47.59"
          ></path>
          <path strokeLinecap="round" d="M57.72 35.07L57.72 89.15"></path>
          <path
            strokeLinecap="round"
            d="M29.59 89.15S25.27 80.5 25.27 74a32.45 32.45 0 1164.89-1.14V74c0 6.49-4.32 15.14-4.32 15.14"
          ></path>
          <path
            strokeLinecap="square"
            d="M29.59 97.81h-2.1a2.13 2.13 0 00-2.16 2.1h0v.09c1 18.23 13.67 30.22 32.39 30.22s31.43-12 32.39-30.22A2.15 2.15 0 0088 97.78H46.94"
          ></path>
          <path strokeLinecap="round" d="M83.67 45.89L81.51 50.22"></path>
          <path strokeLinecap="round" d="M94.49 65.36L90.16 67.52"></path>
          <path strokeLinecap="round" d="M81.51 76.17L77.18 74.01"></path>
          <path strokeLinecap="round" d="M67.27 60.31L65.11 62.47"></path>
          <path strokeLinecap="round" d="M29.59 45.89L33.92 50.22"></path>
          <path strokeLinecap="round" d="M20.94 71.85L25.27 74.01"></path>
          <path strokeLinecap="round" d="M33.92 65.36L38.25 67.52"></path>
          <path strokeLinecap="round" d="M47.8 74.91L49.96 72.75"></path>
        </g>
        <path
          fill="none"
          stroke="#9dc9e4"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M72.86 26.42L72.86 13.44 155.06 13.44 155.06 97.81 113.96 97.81"
        ></path>
        <path
          fill="none"
          stroke="#9dc9e4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M111.8 30.75L137.75 30.75 137.75 56.7"
        ></path>
      </svg>
    </div>
  )
}

export default SvgRefine
