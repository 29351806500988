import React from "react"

const SvgRenoBegins = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="176"
        height="161"
        viewBox="0 0 176 161"
      >
        <path
          fill="none"
          stroke="#9dc9e4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M24.16 37.25V97a8.24 8.24 0 008.24 8.23h0A8.22 8.22 0 0040.63 97V78.44a6.18 6.18 0 016.18-6.18h0A6.18 6.18 0 0153 78.43h0v5.15a7.21 7.21 0 007.2 7.21h0a7.21 7.21 0 007.21-7.21h0V80.5a8.22 8.22 0 018.2-8.24h1.66a12.35 12.35 0 0010.28-5.5l.45-.68"
        ></path>
        <path
          fill="#143554"
          stroke="#143554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M75.64 97h4.12v43.24a4.11 4.11 0 004.1 4.12H98.3a4.11 4.11 0 004.12-4.1V97h4.11"
        ></path>
        <path
          fill="none"
          stroke="#143554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M79.76 96.98L102.42 96.98"
        ></path>
        <path
          fill="none"
          stroke="#143554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M90.06 97v-6.8a8.23 8.23 0 015.18-7.65L146.66 62a8.23 8.23 0 005.18-7.65V43.43a8.24 8.24 0 00-8.24-8.24h-6.17"
        ></path>
        <rect
          width="98.85"
          height="37.07"
          x="38.57"
          y="16.66"
          fill="#9dc9e4"
          stroke="#9dc9e4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          rx="8.24"
        ></rect>
      </svg>
    </div>
  )
}

export default SvgRenoBegins
