import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import {
  standardWrapper,
  headlineOne,
  colors,
  bodyCopy,
  fonts,
} from "../Utilities"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const getData = graphql`
  query allRelatedPosts {
    tests: allWordpressWpTestimonials {
      edges {
        node {
          acf {
            _ikag_testimonial_content
            _ikag_testimonial_name
            _ikag_testimonial_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: true,
  infinite: true,
  speed: 300,
  autoplay: true,
  autoplaySpeed: 25000,
  centerMode: true,
  centerPadding: "0px",
  arrows: true,
  dots: true,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 1,
        centerPadding: "0px",
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        centerPadding: "0px",
      },
    },
  ],
}

const TestSliderSection = styled.section`
  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    h2 {
      ${headlineOne};
      color: ${colors.colorPrimary};
    }
  }

  .slick-track {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .slick-arrow {
    display: none !important;
    z-index: 5000;

    @media (min-width: 768px) {
      display: block !important;
    }

    &::before {
      transition: all 0.3s ease-in-out;
      color: ${colors.colorTertiary};
      font-size: 3rem;
    }

    &:hover {
      &::before {
        color: ${colors.colorSecondary};
      }
    }
  }

  .slick-prev {
    left: 5rem;
  }

  .slick-next {
    right: 5rem;
  }

  ul.slick-dots {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;

    li {
      width: 3rem;
      height: 3rem;
      button {
        width: 3rem;
        height: 3rem;

        &::before {
          display: block;
          width: 3rem;
          height: 3rem;
          font-size: 1.4rem;
          line-height: 2.25;
          color: ${colors.colorSecondary};
          opacity: 0.9;
        }
      }
    }

    li.slick-active {
      button {
        &::before {
          color: ${colors.colorTertiary};
        }
      }
    }
  }
`

const SliderItem = styled.div`
  .slideWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0;

    ${"" /* @media (min-width: 768px) {
      margin: 0 2rem;
    } */}

    @media (min-width: 1025px) {
      margin: 0 2rem;
    }
  }

  .slideContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    width: calc(100%);
    padding: 2rem;
    background: #ecf5fa;
    border-radius: 0.5rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    z-index: 100;

    @media (min-width: 768px) {
      width: calc(100%);
      max-width: 60rem;
    }

    @media (min-width: 1025px) {
      width: calc(35%);
      max-width: 100%;
      margin-right: -5rem;
      padding: 7.5rem 4rem;
    }

    div {
      align-self: center;
    }

    p {
      ${bodyCopy};
      color: ${colors.colorPrimary};
      font-family: ${fonts.fontSecondary};

      font-style: italic;
    }
  }

  .slideImage {
    width: calc(100%);

    @media (min-width: 768px) {
      max-width: 60rem;
    }

    @media (min-width: 1025px) {
      width: calc(65%);
      max-width: 100%;
    }
  }
`

const TestimonalSlider = () => {
  const data = useStaticQuery(getData)
  const testimonials = data.tests.edges
  return (
    <TestSliderSection>
      <div className="wrapper">
        <div className="title">
          <h2>A Few Words from Our Clients</h2>
        </div>
      </div>
      <Slider className="testSlider" {...settings}>
        {testimonials.map((test, index) => {
          return (
            <SliderItem key={index}>
              <div className="slideWrapper">
                <div className="slideContent">
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: test.node.acf._ikag_testimonial_content,
                      }}
                    />
                    <div>
                      <p>{test.node.acf._ikag_testimonial_name}</p>
                    </div>
                  </div>
                </div>
                <div className="slideImage">
                  <Img
                    fluid={
                      test.node.acf._ikag_testimonial_image.localFile
                        .childImageSharp.fluid
                    }
                    alt={test.node.acf._ikag_testimonial_image.alt_text}
                  />
                </div>
              </div>
            </SliderItem>
          )
        })}
      </Slider>
    </TestSliderSection>
  )
}

export default TestimonalSlider
