import React from "react"

const SvgDesign = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="176"
        height="161"
        viewBox="0 0 176 161"
      >
        <rect
          width="117.68"
          height="45.42"
          x="34.32"
          y="90.82"
          fill="none"
          stroke="#143554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          rx="3"
        ></rect>
        <path
          fill="none"
          stroke="#143554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M26.06 105.27l-1.58-3a6.19 6.19 0 013.33-8.1h0L125 53.77a6.19 6.19 0 018.09 3.34L142 78.43"
        ></path>
        <path
          fill="none"
          stroke="#143554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M33.81 92.89l66.31-66.32a6.2 6.2 0 018.75 0h0l14.71 14.71"
        ></path>
        <circle cx="48.77" cy="113.53" r="4.13" fill="#143554"></circle>
        <path
          fill="#e6e6e7"
          stroke="#143554"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M66.77 100.27H90.44999999999999V129.07999999999998H66.77z"
        ></path>
        <path
          fill="#9dc9e4"
          stroke="#143554"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M90.43 100.27H114.11000000000001V129.07999999999998H90.43z"
        ></path>
        <path
          fill="#65646a"
          stroke="#143554"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M114.09 100.27H137.77V129.07999999999998H114.09z"
        ></path>
      </svg>
    </div>
  )
}

export default SvgDesign
