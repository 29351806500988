import React from "react"

const SvgFinalizing = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="176"
        height="161"
        viewBox="0 0 176 161"
      >
        <path
          fill="none"
          stroke="#143554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M131.32 104C113.36 125.89 88 137.36 88 137.36S62.65 125.89 44.7 104"
          data-name="heart bottom"
        ></path>
        <path
          fill="#143554"
          d="M137.54 95.5l-14.25-11.08V58.93h-17.64V70.7L88 58.93 37.72 94.31c-6.24-10.11-10.5-21.91-10.5-35.38A35.26 35.26 0 0188 34.57a35.26 35.26 0 0160.78 24.36c0 14-4.59 26.21-11.24 36.57z"
        ></path>
        <path
          fill="none"
          stroke="#143554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M137.54 95.5l-14.25-11.08V58.93h-17.64V70.7L88 58.93 37.72 94.31c-6.24-10.11-10.5-21.91-10.5-35.38A35.26 35.26 0 0188 34.57a35.26 35.26 0 0160.78 24.36c0 14-4.59 26.21-11.24 36.57z"
        ></path>
        <path
          fill="none"
          stroke="#9dc9e4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M74.28 117.75L74.28 104.03 101.72 104.03 101.72 117.75"
        ></path>
      </svg>
    </div>
  )
}

export default SvgFinalizing
