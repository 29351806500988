import React, { useState } from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import {
  colors,
  headlineOne,
  medWrapper,
  headlineThree,
  fonts,
} from "../Utilities"

import SvgDoor from "../elements/SvgDoor"
import SvgNumbers from "../elements/SvgNumbers"
import SvgDesign from "../elements/SvgDesign"
import SvgRefine from "../elements/SvgRefine"
import RenoProcessStep from "./RenoProcessStep"
import { IoMdClose } from "react-icons/io"

const PhoneOne = styled.div`
  position: relative;
  padding: 6rem 0;
  background-color: rgba(157, 201, 228, 0.3);
  overflow: hidden;

  .title {
    width: 100%;
    padding-bottom: 5rem;
    text-align: center;

    h2 {
      ${headlineOne};
      color: ${colors.colorPrimary};
      span {
        display: block;
      }
    }
  }

  .steps {
    ${medWrapper};
    overflow: hidden;
  }

  .stepContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 5rem;
    width: 100%;
    height: 100%;
    background: ${colors.colorPrimary};

    &__container {
      max-width: 80rem;
      margin: 0;
      align-self: center;
      text-align: center;

      &--icon {
        margin: 0 auto 4rem;
        padding: 3rem;
        transition: all 0.3s ease;
        transform: scale(1);
        border-radius: 50%;
        background-color: ${colors.white};
        width: 21rem;
        height: 21rem;

        svg {
          width: 100%;
          height: 100%;
          margin: auto;
          transition: all 0.3s ease;
          transform: scale(1);
        }
      }

      .headline {
        ${headlineOne};
        margin: 0;
        margin-bottom: 1rem;
        color: ${colors.white};
      }

      .content {
        ${headlineThree};
        color: ${colors.white};
      }
    }

    &__button {
      position: absolute;
      top: 2rem;
      right: 2rem;

      button {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background: ${colors.colorSecondary};
        font-family: ${fonts.fontAwesome};
        overflow: hidden;
        border: 0.25rem solid ${colors.white};

        &:hover {
          cursor: pointer;
        }

        svg {
          width: 100%;
          height: 100%;
          fill: ${colors.white};
        }
      }
    }
  }
`

const RenoProcessPhaseOne = () => {
  const stepProcessTitle = [
    "Hello & First Contact!",
    "Know Your Numbers",
    "Renovation Design",
    "Refining Your Vision",
  ]

  const stepProcessContent = [
    "It starts with a phone call to book an in-person new client meeting. During this meeting at your property, we gather your goals, vision and reason for renovating, along with other key details, such as measurements, pictures, and other custom preferences, to provide a qualified estimate, as well as to understand the timeline you’re looking at. This is just the beginning of earning your trust.",
    "We’ll present your custom estimate based on all the details gathered. Note we will never low ball our pricing as we want you to have a high level of detail, outlining items such as materials, to give you as realistic an estimate as possible. Confident and ready to move forward, the deposit is submitted.",
    "All visual decisions are made here. It’s really one of the most fun parts of your renovation as your vision begins to take shape! Materials are chosen and colour options are decided on, not to mention the actual designing of your renovation. Our design team focuses on your lifestyle while adding little “extras” for delight and functional ease. Even more, you get to see this come to life with our unique visual planning techniques and software. No boring blueprints for you!",
    "Crucial to your renovation is trusting who will be in your house and interacting with your family. A scheduled Trade Walk-Through allows the individuals who will work in your home to come at a pre-determined time, identifying any potential hiccups and provide solutions that could impact the renovation’s timeline, design, and budget. This is planning at its finest, capturing these details before we even begin to order nails. Plus, we promise to bring the coffee!",
  ]

  const stepIcons = [<SvgDoor />, <SvgNumbers />, <SvgDesign />, <SvgRefine />]

  const [content, changeContent] = useState(0)
  const [open, toggle] = useState(true)
  const props = useSpring({
    transform: open ? "translateX(-100%)" : "translateX(0%)",
  })
  return (
    <PhoneOne className="renoPhaseOne">
      <div className="renoPhaseOne__wrapper">
        <div className="title">
          <h2>
            <span>Phase I:</span>
            <span>Renovation Development</span>
          </h2>
        </div>
        <div className="steps">
          <RenoProcessStep
            icon="door"
            title={"Hello & First Contact!"}
            open={open}
            toggle={toggle}
            stepNumber={0}
            changeContent={changeContent}
            color={colors.white}
          />
          <RenoProcessStep
            icon="numbers"
            title={"Know Your Numbers"}
            open={open}
            toggle={toggle}
            stepNumber={1}
            changeContent={changeContent}
            color={colors.white}
          />
          <RenoProcessStep
            icon="design"
            title={"Renovation Design"}
            open={open}
            toggle={toggle}
            stepNumber={2}
            changeContent={changeContent}
            color={colors.white}
          />
          <RenoProcessStep
            icon="refine"
            title={"Refining Your Vision"}
            open={open}
            toggle={toggle}
            stepNumber={3}
            changeContent={changeContent}
            color={colors.white}
          />
        </div>
      </div>
      <animated.div
        className="stepContent"
        style={props}
        onClick={() => toggle(!open)}
      >
        <div className="stepContent__container">
          <div className="stepContent__container--icon">
            {stepIcons[content]}
          </div>
          <p className="headline">{stepProcessTitle[content]}</p>
          <p className="content">{stepProcessContent[content]}</p>
        </div>
        <div className="stepContent__button">
          <button>
            <IoMdClose />
          </button>
        </div>
      </animated.div>
    </PhoneOne>
  )
}

export default RenoProcessPhaseOne
