import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import BgImg from "gatsby-background-image"
import WordpressWysiwyg from "./WordPressWysiwyg"
import {
  medWrapper,
  headlineOne,
  colors,
  fonts,
  headlineThree,
} from "../Utilities"

const IBBCSection = styled.section`
  padding: 2rem 0;

  @media (min-width: 768px) {
    padding: 10rem 0 5rem;
  }

  .wrapper {
    ${medWrapper};
    flex-direction: row-reverse;
    overflow: hidden;
  }

  .ibbcContent {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(50% - 7.5rem);
      margin-left: 7.5rem;
    }

    &__title {
      h2 {
        ${headlineOne};
        color: ${colors.colorPrimary};
      }
    }

    &__wysiwyg {
      li {
        color: ${colors.colorPrimary};
        font-family: ${fonts.fontSecondary};
        font-style: italic;
      }

      blockquote {
        width: 100%;
        margin: 2rem auto 1rem;
        padding: 0;
        border-right: none;
        border-left: none;

        p {
          ${headlineThree};
          color: ${colors.colorSecondary};

          &::before,
          &::after {
            display: none;
            content: "";
          }
        }
      }
    }
  }

  .ibbcImage {
    position: relative;
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(50% - 10rem);
      margin: 0 5rem;
    }

    &__image {
    }

    &__img {
      &--img {
        position: absolute;
        top: 10rem;
        left: 5rem;
        bottom: -10rem;
        right: -5rem;
        z-index: 1;

        &--wrapper {
          position: relative;
          margin-top: 10rem;
        }
      }

      &--bgimg {
        position: absolute;
        top: -10rem;
        left: -5rem;
        right: 5rem;
        bottom: 10rem;
        z-index: -1;

        .gatsby-image-wrapper {
          width: 100%;
          height: 100%;
          position: static !important;
          background-position: bottom center;
          background-repeat: no-repeat;
          background-size: cover;
          z-index: -1;
        }
      }
    }
  }
`

const ImageBgBesideContent = ({
  imgFluid,
  imgAlt,
  bgFluid,
  title,
  content,
}) => {
  return (
    <IBBCSection>
      <div className="wrapper">
        <div className="ibbcContent">
          <div className="ibbcContent__title">
            <h2>{title}</h2>
          </div>
          <div className="ibbcContent__wysiwyg">
            <WordpressWysiwyg content={content} />
          </div>
        </div>
        <div className="ibbcImage">
          <div className="ibbcImage__img--img--wrapper">
            <div className="ibbcImage__image">
              <Img fluid={imgFluid} alt={imgAlt} />
            </div>
            <div className="ibbcImage__img--bgimg">
              <BgImg Tag="div" fluid={bgFluid} />
            </div>
          </div>
        </div>
      </div>
    </IBBCSection>
  )
}

export default ImageBgBesideContent
