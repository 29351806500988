import React from "react"
import styled from "styled-components"
import { colors, headlineOne } from "../Utilities"

import SvgDoor from "../elements/SvgDoor"
import SvgNumbers from "../elements/SvgNumbers"
import SvgDesign from "../elements/SvgDesign"
import SvgRefine from "../elements/SvgRefine"
import SvgFinalizing from "../elements/SvgFinalizing"
import SvgRenoBegins from "../elements/SvgRenoBegins"
import SvgFinishing from "../elements/SvgFinishing"
import SvgFinal from "../elements/SvgFinal"

const StyledStep = styled.div`
  width: calc(50%);
  margin-bottom: 2.5rem;
  text-align: center;

  @media (min-width: 768px) {
    width: calc(25%);
    margin-bottom: 0;
  }

  .step__icon {
    width: 12.5rem;
    height: 12.5rem;
    margin: auto;
    padding: 3rem;
    transition: all 0.3s ease;
    transform: scale(1);
    border-radius: 50%;
    background-color: ${props => props.color};

    @media (min-width: 375px) {
      width: 15rem;
      height: 15rem;
    }

    @media (min-width: 768px) {
      width: 21rem;
      height: 21rem;
    }

    div {
      width: 100%;
      height: 100%;
      margin: auto;
    }

    svg {
      width: 100%;
      height: 100%;
      margin: auto;
      transition: all 0.3s ease;
      transform: scale(1);
    }
  }

  h3 {
    ${headlineOne};
    color: ${colors.colorPrimary};
    font-style: italic;
  }

  &:hover {
    cursor: pointer;

    .step__icon {
      svg {
        transform: scale(1.15);
      }
    }
  }
`

const RenoProcessStep = ({
  icon,
  title,
  open,
  toggle,
  stepNumber,
  changeContent,
  color,
}) => {
  let displayIcon = ""
  if (icon === "door") {
    displayIcon = <SvgDoor />
  } else if (icon === "numbers") {
    displayIcon = <SvgNumbers />
  } else if (icon === "design") {
    displayIcon = <SvgDesign />
  } else if (icon === "refine") {
    displayIcon = <SvgRefine />
  } else if (icon === "finalizing") {
    displayIcon = <SvgFinalizing />
  } else if (icon === "begins") {
    displayIcon = <SvgRenoBegins />
  } else if (icon === "finishing") {
    displayIcon = <SvgFinishing />
  } else if (icon === "final") {
    displayIcon = <SvgFinal />
  }

  const handleDisplayContent = () => {
    changeContent(stepNumber)
    toggle(!open)
  }

  return (
    <StyledStep color={color} className="step" onClick={handleDisplayContent}>
      <div className="step__icon">{displayIcon}</div>
      <h3>{title}</h3>
    </StyledStep>
  )
}

export default RenoProcessStep
