import React from "react"

const SvgFinal = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2.44in"
        height="2.24in"
        viewBox="0 0 176 161"
      >
        <path
          fill="#143554"
          stroke="#143554"
          strokeMiterlimit="10"
          strokeWidth="3"
          d="M40.49 137.88a1.12 1.12 0 01-1-.58l-4.39-7.79a1.18 1.18 0 01-.08-.92L54.24 70.9a27.28 27.28 0 1115.48 4.81h-.47L64.3 90l4 10a1.14 1.14 0 01-.44 1.39l-9 5.83L62.41 118a1.16 1.16 0 01-.45 1.3l-8.42 5.7-3.06 9.19a1.2 1.2 0 01-.73.73l-8.9 2.9a1.13 1.13 0 01-.36.06z"
        ></path>
        <rect
          width="42"
          height="82"
          x="105.03"
          y="55.72"
          fill="#9dc9e4"
          stroke="#143554"
          strokeMiterlimit="10"
          strokeWidth="2"
          rx="8.47"
        ></rect>
        <circle
          cx="117.03"
          cy="67.72"
          r="7.5"
          fill="#fff"
          stroke="#143554"
          strokeMiterlimit="10"
          strokeWidth="2"
        ></circle>
        <circle
          cx="71.03"
          cy="40.72"
          r="8.5"
          fill="#fff"
          stroke="#9dc9e4"
          strokeMiterlimit="10"
          strokeWidth="2"
        ></circle>
        <path
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="3"
          d="M71 40.72h33a13 13 0 0113 13v15"
        ></path>
        <circle
          cx="125.91"
          cy="104.31"
          r="10.14"
          fill="none"
          stroke="#123554"
          strokeMiterlimit="10"
          strokeWidth="0.25"
          data-name="Ellipse inside"
        ></circle>
        <circle
          cx="125.91"
          cy="104.31"
          r="16.99"
          fill="none"
          stroke="#123554"
          strokeMiterlimit="10"
          strokeWidth="0.5"
          data-name="Ellipse outside"
        ></circle>
        <g fill="#123554">
          <path
            d="M112.79 99.02H113.18V102.06H112.79z"
            transform="rotate(-73.73 112.985 100.537)"
          ></path>
          <path d="M115.78 98l-1-.66-.11-.42h1.79l.27-.38h-2.11l-.45-1.63-.27.4.41 1.41c0 .12.09.24.14.38l-1.2-.83-.23.32 2.51 1.72zM117.56 95.73l-.84-1c-.33-.39-.57-.66-.84-.93a10.17 10.17 0 001.09.41l1.73.54.3-.26-2-2.3-.28.23.83 1c.31.36.55.63.85.92a7.83 7.83 0 00-1.05-.4l-1.74-.53-.33.28 2 2.3zM121.06 93.45a1.41 1.41 0 00.64-2 1.37 1.37 0 00-1.87-.85 1.44 1.44 0 00-.64 2 1.38 1.38 0 001.87.85zM120 90.87a1.08 1.08 0 011.38.73c.28.63.19 1.3-.37 1.55a1.09 1.09 0 01-1.39-.73c-.32-.61-.27-1.29.38-1.55zM124.13 92.49l.28-1.62c.06-.33.11-.67.14-1a8.92 8.92 0 00.29 1l.52 1.53h.41l.58-3.11h-.4l-.25 1.56c-.06.38-.11.73-.15 1.07-.08-.32-.19-.67-.31-1l-.51-1.5h-.41l-.25 1.58a10.63 10.63 0 00-.15 1.08c-.08-.29-.2-.66-.33-1l-.5-1.49h-.41l1 3zM129.71 92l1 .36v1l.4.15V90.3l-.45-.16-2 2.51.38.14zm.76-1l.32-.47v1.5l-.86-.31zM134.15 95.71a2.64 2.64 0 00.8.5l1-1.07-.74-.68-.21.23.46.42-.61.67a1.24 1.24 0 01-.44-.3 1.1 1.1 0 010-1.69 1.12 1.12 0 011.72-.08 1.56 1.56 0 01.41.55l.29-.17a1.85 1.85 0 00-.47-.61 1.52 1.52 0 00-2.27 0 1.43 1.43 0 00.12 2.19zM136 98.08c.34.56.92.78 1.72.29l1.51-.92-.21-.34-1.53.94c-.57.35-1 .26-1.21-.12s-.13-.76.45-1.11l1.54-.94-.21-.34-1.53.93c-.76.53-.82 1.08-.53 1.61zM137.3 100.73l.12.38 1.24-.36 2 .48-.13-.43-.93-.18c-.25 0-.47-.09-.68-.11l.51-.47.68-.65L140 99l-1.41 1.42zM137.26 108l1.24.46-.13.35c-.14.33-.35.43-.77.36a3.67 3.67 0 00-.75-.06l-.14.37a5.53 5.53 0 01.86.08.77.77 0 00.73-.2.8.8 0 00.53.81.79.79 0 00.62 0 1.23 1.23 0 00.54-.74 4.38 4.38 0 00.21-.73l-2.81-1zm1.37.94l.14-.37 1 .39a1.56 1.56 0 01-.1.37c-.15.39-.41.6-.78.46a.61.61 0 01-.26-.79z"></path>
          <path d="M135.3 111.75L135.57 111.93 136.31 110.84 137.22 111.45 136.56 112.43 136.83 112.61 137.48 111.63 138.28 112.16 137.59 113.2 137.86 113.38 138.77 112.02 136.25 110.33 135.3 111.75z"></path>
          <path d="M134.4 112.73l.87 1c.33.38.58.65.85.92a8.27 8.27 0 00-1.09-.38l-1.75-.51-.28.24 2 2.27.28-.24-.84-1c-.32-.36-.57-.63-.87-.91a9.48 9.48 0 001.06.37l1.75.5.32-.28-2-2.27zM130.94 115.09a1.42 1.42 0 00-.6 2 1.39 1.39 0 001.89.82 1.44 1.44 0 00.6-2 1.39 1.39 0 00-1.89-.82zm1.17 2.56a1.08 1.08 0 01-1.4-.71c-.29-.63-.21-1.29.35-1.55s1.12.11 1.4.7.24 1.29-.35 1.56zM127.66 116.12l-.58 3.17.41-.06.27-1.57c.07-.41.13-.81.16-1.17.14.34.31.69.5 1.07l.71 1.4.42-.07-1.47-2.84zM125.48 117.21l-1.08-.08-.25-1h-.42l.81 3.11h.47l1.26-3h-.41zm-.47 1.15a3.86 3.86 0 00-.19.54c0-.18-.07-.36-.12-.56l-.23-.9.91.07z"></path>
          <path d="M122.62 115.79L122.24 115.68 121.45 118.26 120.56 117.99 120.47 118.31 122.62 118.97 122.71 118.65 121.83 118.38 122.62 115.79z"></path>
          <path
            d="M118.21 116.07H121.25V116.46H118.21z"
            transform="rotate(-62.64 119.729 116.27)"
          ></path>
          <path d="M118.34 113.48a1.42 1.42 0 00-2.11.33 1.37 1.37 0 00.07 2 1.44 1.44 0 002.1-.32 1.4 1.4 0 00-.06-2.01zm-.27 1.79c-.42.52-1 .77-1.55.36a1.09 1.09 0 010-1.57c.44-.53 1.08-.75 1.55-.35a1.07 1.07 0 010 1.56zM116.4 111.58l-1.08.72c-.42.28-.71.49-1 .72a10 10 0 00.53-1l.74-1.66-.22-.33-2.52 1.69.2.31 1-.71a11.26 11.26 0 001-.73 7.29 7.29 0 00-.52 1l-.74 1.66.24.36 2.53-1.69zM113.32 108.73c0-.44.11-.65.38-.74s.57.05.7.43a1.4 1.4 0 010 .73h.34a1.53 1.53 0 00-.07-.8c-.23-.68-.73-.87-1.17-.72s-.57.48-.59 1-.09.66-.36.76a.49.49 0 01-.55-.39 1.11 1.11 0 010-.6h-.34a1.36 1.36 0 00.08.69.88.88 0 001.1.66c.33-.12.47-.5.48-1.02z"></path>
        </g>
        <path
          fill="#123554"
          d="M132.63 102.12a.18.18 0 000-.24l-.37-.37a.18.18 0 00-.1 0c-.06 0-.14.06-.38-.25a1 1 0 01-.28-.72c0-.28-.38-.57-.38-.57a3.69 3.69 0 00-4-.78h-.05a.25.25 0 000 .12s1.87-.08 2.41.78a3.6 3.6 0 00-.26.68l-.3.32a.06.06 0 000 .07s-1.91 1.77-2.47 2.33c0 0-.24-.09-1.06.65a20 20 0 00-3.34 4.55v.47a.36.36 0 010 .1v.1a1.11 1.11 0 00.07.16v.07a1.12 1.12 0 00.2.17s.53.26.78 0 .07-.28 0-.65a2.84 2.84 0 01.71-1.62 24.83 24.83 0 003.19-3.19 1.58 1.58 0 01.47-.82l2.11-1.92h.06l.33-.28a1.05 1.05 0 011.08.13.89.89 0 01.38.73v.09c.12.15.25.3.38.44s.31-.11.31-.11l.49-.43z"
        ></path>
        <path
          fill="none"
          stroke="#123554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.25"
          d="M132.63 102.12h0a.05.05 0 000 0h0a.18.18 0 000-.24l-.37-.37a.18.18 0 00-.1 0c-.06 0-.14.06-.38-.25a1 1 0 01-.28-.72c0-.28-.38-.57-.38-.57a3.69 3.69 0 00-4-.78h-.05v0h0a.1.1 0 000 0h0a.25.25 0 000 .12s1.87-.08 2.41.78a3.6 3.6 0 00-.26.68l-.3.32a.06.06 0 000 .07s-1.91 1.77-2.47 2.33c0 0-.24-.09-1.06.65a20 20 0 00-3.34 4.55h0v.35h0v.12a.36.36 0 010 .1h0v.1h0a1.11 1.11 0 00.07.16h0v.07h0v0h0a1.12 1.12 0 00.2.17s.53.26.78 0 .07-.28 0-.65a2.84 2.84 0 01.71-1.62 24.83 24.83 0 003.19-3.19 1.58 1.58 0 01.47-.82l2.11-1.92h.06l.33-.28a1.05 1.05 0 011.08.13.89.89 0 01.38.73v.09c.12.15.25.3.38.44s.31-.11.31-.11l.49-.43h0z"
        ></path>
        <path
          fill="#123554"
          d="M119.77 102.12a.17.17 0 010-.24l.37-.37a.18.18 0 01.1 0c.06 0 .13.06.38-.25a1 1 0 00.28-.72c0-.28.38-.57.38-.57a3.68 3.68 0 014-.78.49.49 0 010 .12s-1.87-.08-2.42.78a3 3 0 01.26.68l.3.32a.07.07 0 010 .07s1.9 1.77 2.47 2.33c0 0 .24-.09 1.06.65a20.09 20.09 0 013.33 4.53.24.24 0 000 .08.64.64 0 000 .07v.2a.49.49 0 010 .12.43.43 0 010 .11v.1a.51.51 0 01-.07.17v.06a.55.55 0 01-.2.17s-.52.27-.78 0-.07-.27 0-.64a2.89 2.89 0 00-.71-1.62s-2.75-2.73-2.88-3.27a1.72 1.72 0 00-.47-.8l-2.11-1.92H123a3.63 3.63 0 01-.33-.28 1 1 0 00-1.08.13.89.89 0 00-.38.73v.09a5.07 5.07 0 01-.37.44c-.09.06-.31-.11-.31-.11l-.49-.43z"
        ></path>
        <path
          fill="none"
          stroke="#123554"
          strokeMiterlimit="10"
          strokeWidth="0.25"
          d="M119.77 102.12h0a.17.17 0 010-.24l.37-.37a.18.18 0 01.1 0c.06 0 .13.06.38-.25a1 1 0 00.28-.72c0-.28.38-.57.38-.57a3.68 3.68 0 014-.78h0a.1.1 0 010 0h0a.49.49 0 010 .12s-1.87-.08-2.42.78a3 3 0 01.26.68l.3.32a.07.07 0 010 .07s1.9 1.77 2.47 2.33c0 0 .24-.09 1.06.65a20.09 20.09 0 013.33 4.53v0a.24.24 0 000 .08h0a.64.64 0 000 .07h0v.2h0a.49.49 0 010 .12h0a.43.43 0 010 .11h0v.1h0a.51.51 0 01-.07.17h0v.06s0 0 0 0h0a.55.55 0 01-.2.17s-.52.27-.78 0-.07-.27 0-.64a2.89 2.89 0 00-.71-1.62s-2.75-2.73-2.88-3.27a1.72 1.72 0 00-.47-.8l-2.11-1.92H123a3.63 3.63 0 01-.33-.28 1 1 0 00-1.08.13.89.89 0 00-.38.73v.09a5.07 5.07 0 01-.37.44c-.09.06-.31-.11-.31-.11l-.49-.43h0z"
        ></path>
        <circle
          cx="112.38"
          cy="104.31"
          r="0.53"
          fill="#123554"
          data-name="Ellipse left"
        ></circle>
        <circle
          cx="139.34"
          cy="104.31"
          r="0.53"
          fill="#123554"
          data-name="Ellipse right"
        ></circle>
      </svg>
    </div>
  )
}

export default SvgFinal
