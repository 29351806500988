import React, { useState } from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import {
  colors,
  headlineOne,
  medWrapper,
  headlineThree,
  fonts,
} from "../Utilities"

import SvgFinalizing from "../elements/SvgFinalizing"
import SvgRenoBegins from "../elements/SvgRenoBegins"
import SvgFinishing from "../elements/SvgFinishing"
import SvgFinal from "../elements/SvgFinal"
import { IoMdClose } from "react-icons/io"
import RenoProcessStep from "./RenoProcessStep"

const PhoneTwo = styled.div`
  position: relative;
  padding: 6rem 0;
  overflow: hidden;

  .title {
    width: 100%;
    padding-bottom: 5rem;
    text-align: center;

    h2 {
      ${headlineOne};
      color: ${colors.colorPrimary};
      span {
        display: block;
      }
    }
  }

  .steps {
    ${medWrapper};
    overflow: hidden;
  }

  .stepContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 5rem;
    width: 100%;
    height: 100%;
    background: ${colors.colorPrimary};

    &__container {
      max-width: 80rem;
      margin: 0;
      align-self: center;
      text-align: center;

      &--icon {
        margin: 0 auto 4rem;
        padding: 3rem;
        transition: all 0.3s ease;
        transform: scale(1);
        border-radius: 50%;
        background-color: ${colors.white};
        width: 21rem;
        height: 21rem;

        svg {
          width: 100%;
          height: 100%;
          margin: auto;
          transition: all 0.3s ease;
          transform: scale(1);
        }
      }

      .headline {
        ${headlineOne};
        margin: 0;
        margin-bottom: 1rem;
        color: ${colors.white};
      }

      .content {
        ${headlineThree};
        color: ${colors.white};
      }
    }

    &__button {
      position: absolute;
      top: 2rem;
      right: 2rem;

      button {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background: ${colors.colorSecondary};
        font-family: ${fonts.fontAwesome};
        overflow: hidden;
        border: 0.25rem solid ${colors.white};

        &:hover {
          cursor: pointer;
        }

        svg {
          width: 100%;
          height: 100%;
          fill: ${colors.white};
        }
      }
    }
  }
`

const RenoProcessPhaseTwo = () => {
  const stepProcessTitle = [
    "Finalizing the Numbers",
    "Renovation Begins",
    "Finishing Touches",
    "Final Walkthrough",
  ]

  const stepProcessContent = [
    "Providing the quote in person, we’ll walk you through the numbers based on the final design and refinements made from the initial estimate. Once approved, you’ll see all the planning efforts beautifully unfold before your eyes.",
    "Schedules developed, supplies ordered, and trades ready to start, your renovation can now fully begin. Throughout the project, you’ll continue to receive updates about your timeline, project status, and other key info. At any point, you are encouraged to reach out to your Project Manager and ask questions but know that this is where you can sit back and relax and watch the carefully planned magic. Throughout the renovation, we take all steps necessary to protect your home from increased traffic and dust, keeping you comfortable throughout our time together.",
    "Keeping your home clean beginning to end includes post-renovation. Once finished, we’ll have your home and furnace professionally cleaned. Breathe in the new renovation...not the renovation dust!",
    "Your Project Manager will walk you through your renovation, addressing any final concerns or adjustments you may have. That’s it! Renovation complete, you’re ready to settle in and enjoy. While we won’t be in your home every day, we will follow up to see how you’re enjoying life in your “new” home.",
  ]

  const stepIcons = [
    <SvgFinalizing />,
    <SvgRenoBegins />,
    <SvgFinishing />,
    <SvgFinal />,
  ]

  const [content, changeContent] = useState(0)
  const [open, toggle] = useState(true)
  const props = useSpring({
    transform: open ? "translateX(100%)" : "translateX(0%)",
  })

  return (
    <PhoneTwo className="renoPhaseTwo">
      <div className="renoPhaseTwo__wrapper">
        <div className="title">
          <h2>
            <span>Phase II:</span>
            <span>Renovation Execution</span>
          </h2>
        </div>
        <div className="steps">
          <RenoProcessStep
            icon="finalizing"
            title={"Finalizing the Numbers"}
            open={open}
            toggle={toggle}
            stepNumber={0}
            changeContent={changeContent}
            color={"rgba(158, 201, 228, 0.3)"}
          />

          <RenoProcessStep
            icon="begins"
            title={"Renovation Begins"}
            open={open}
            toggle={toggle}
            stepNumber={1}
            changeContent={changeContent}
            color={"rgba(158, 201, 228, 0.3)"}
          />

          <RenoProcessStep
            icon="finishing"
            title={"Finishing Touches"}
            open={open}
            toggle={toggle}
            stepNumber={2}
            changeContent={changeContent}
            color={"rgba(158, 201, 228, 0.3)"}
          />

          <RenoProcessStep
            icon="final"
            title={"Final Walkthrough"}
            open={open}
            toggle={toggle}
            stepNumber={3}
            changeContent={changeContent}
            color={"rgba(158, 201, 228, 0.3)"}
          />
        </div>
      </div>
      <animated.div
        className="stepContent"
        style={props}
        onClick={() => toggle(!open)}
      >
        <div className="stepContent__container">
          <div className="stepContent__container--icon">
            {stepIcons[content]}
          </div>
          <p className="headline">{stepProcessTitle[content]}</p>
          <p className="content">{stepProcessContent[content]}</p>
        </div>
        <div className="stepContent__button">
          <button>
            <IoMdClose />
          </button>
        </div>
      </animated.div>
    </PhoneTwo>
  )
}

export default RenoProcessPhaseTwo
