import React, { useState } from "react"
import styled from "styled-components"
import {
  medWrapper,
  headlineOne,
  colors,
  headlineThree,
  buttonOne,
} from "../Utilities"
import FullServicesRenosImages from "./FullServicesRenosImages"
import { Link } from "gatsby"

const FullServiceRenosSection = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .fullRenoTitle {
    width: 100%;
    text-align: center;

    h2 {
      ${headlineOne};
      color: ${colors.colorPrimary};
    }
  }

  .fullRenoNav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding-bottom: 5rem;

    button {
      ${headlineThree};
      position: relative;
      width: 100%;
      margin: 1rem 2rem;
      padding: 0.5rem;
      transition: all 0.3s ease-in-out;
      background: transparent;
      border: none;

      @media(min-width: 768px) {
        width: auto;
        margin: auto 2rem;
      }

      &:focus {
        outline: none;
      }

      &:after {
        position: absolute;
        right: 0;
        bottom: -0.5rem;
        left: 0;
        height: 0.8rem;
        transition: all 0.3s ease-in-out;
        background-color: ${colors.colorPrimary};
        opacity: 0;
        content: "";
      }

      &:hover {
        cursor: pointer;
        color: ${colors.colorPrimary};

        &:after {
          opacity: 1;
        }
      }
    }
  }

  ${props =>
    props.active === "kitchens" &&
    `
    button.kitchenBtn {
      cursor: pointer;
      color: ${colors.colorPrimary};
      &:after {
        opacity: 1;
      }
    }
  `}

  ${props =>
    props.active === "bathrooms" &&
    `
    button.bathroomsBtn {
      cursor: pointer;
      color: ${colors.colorPrimary};
      &:after {
        opacity: 1;
      }
    }
  `}

  ${props =>
    props.active === "basements" &&
    `
    button.basementsBtn {
      cursor: pointer;
      color: ${colors.colorPrimary};
      &:after {
        opacity: 1;
      }
    }
  `}

  ${props =>
    props.active === "fullInteriors" &&
    `
    button.fullInteriorsBtn {
      cursor: pointer;
      color: ${colors.colorPrimary};
      &:after {
        opacity: 1;
      }
    }
  `}

  .images-wrapper {
    position: relative;
    width: 100%;
    height: 15rem;
    overflow: hidden;

    @media (min-width: 375px) {
      height: 20rem;
    }

    @media (min-width: 768px) {
      height: 55rem;
    }
  }

  .fullRenoLinkMore {
    width: 100%;
    padding-top: 5rem;
    text-align: center;

    a {
      ${buttonOne};
    }
  }

`

const FullServiceRenos = ({ kitchens, bathroom, basement, fullInterior }) => {
  const [whatsActive, changeWhatsActive] = useState({ active: "kitchens" })

  const handleChangeWhatsActive = e => {
    changeWhatsActive({ active: e.currentTarget.dataset.images })
  }

  return (
    <FullServiceRenosSection active={whatsActive.active}>
      <div className="wrapper">
        <div className="fullRenoTitle">
          <h2>Full-Service Renovations</h2>
        </div>
        <div className="fullRenoNav">
          <button
            className="kitchenBtn"
            data-images={"kitchens"}
            onClick={e => {
              handleChangeWhatsActive(e)
            }}
          >
            Kitchen
          </button>
          <button
            className="bathroomsBtn"
            data-images={"bathrooms"}
            onClick={e => {
              handleChangeWhatsActive(e)
            }}
          >
            Bathrooms
          </button>
          <button
            className="basementsBtn"
            data-images={"basements"}
            onClick={e => {
              handleChangeWhatsActive(e)
            }}
          >
            Basements
          </button>
          <button
            className="fullInteriorsBtn"
            data-images={"fullInteriors"}
            onClick={e => {
              handleChangeWhatsActive(e)
            }}
          >
            Full Interiors
          </button>
        </div>
        <div className="images-wrapper">
          <FullServicesRenosImages
            images={kitchens}
            active={whatsActive.active === "kitchens" ? true : false}
          />
          <FullServicesRenosImages
            images={bathroom}
            active={whatsActive.active === "bathrooms" ? true : false}
          />
          <FullServicesRenosImages
            images={basement}
            active={whatsActive.active === "basements" ? true : false}
          />
          <FullServicesRenosImages
            images={fullInterior}
            active={whatsActive.active === "fullInteriors" ? true : false}
          />
        </div>
        <div className="fullRenoLinkMore">
          <Link to="/portfolio">More of our beautiful custom renovations</Link>
        </div>
      </div>
    </FullServiceRenosSection>
  )
}

export default FullServiceRenos
