import React from "react"

const SvgFinishing = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="176"
        height="161"
        viewBox="0 0 176 161"
      >
        <g
          fill="none"
          stroke="#143554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path
            d="M33.13 11.31a41.51 41.51 0 017.16 9.55 41.47 41.47 0 017.15-9.55"
            data-name="1"
          ></path>
          <path
            d="M71.3 11.31a41.51 41.51 0 017.16 9.55 41.47 41.47 0 017.15-9.55"
            data-name="2"
          ></path>
          <path
            d="M109.47 11.31a41.51 41.51 0 017.16 9.55 41.23 41.23 0 017.16-9.55"
            data-name="3"
          ></path>
          <path
            d="M147.64 11.31a41.51 41.51 0 017.16 9.55 41.68 41.68 0 017.2-9.55"
            data-name="4"
          ></path>
          <path
            d="M14 32.79a41.17 41.17 0 017.16 9.54 41.17 41.17 0 017.16-9.54"
            data-name="5"
          ></path>
          <path
            d="M52.21 32.79a41 41 0 017.16 9.54 41.17 41.17 0 017.16-9.54"
            data-name="6"
          ></path>
          <path
            d="M90.39 32.79a41.41 41.41 0 017.15 9.54 41.17 41.17 0 017.16-9.54"
            data-name="7"
          ></path>
          <path
            d="M128.56 32.79a41.41 41.41 0 017.15 9.54 41.17 41.17 0 017.16-9.54"
            data-name="8"
          ></path>
          <path d="M118 61.41a43.06 43.06 0 015.76-7.15" data-name="9"></path>
          <path
            d="M147.64 54.26a41.17 41.17 0 017.16 9.54 41.13 41.13 0 017.2-9.54"
            data-name="10"
          ></path>
          <path
            d="M128.56 75.73a41.41 41.41 0 017.15 9.54 41.17 41.17 0 017.16-9.54"
            data-name="11"
          ></path>
          <path
            d="M109.47 97.2a41.17 41.17 0 017.16 9.54 41 41 0 017.16-9.54"
            data-name="12"
          ></path>
          <path
            d="M147.64 97.2a41.17 41.17 0 017.16 9.54 41.13 41.13 0 017.2-9.54"
            data-name="13"
          ></path>
          <path
            d="M97.54 128.21a41.17 41.17 0 017.16-9.54"
            data-name="14"
          ></path>
          <path
            d="M128.56 118.67a41.41 41.41 0 017.15 9.54 41.17 41.17 0 017.16-9.54"
            data-name="15"
          ></path>
          <path
            d="M109.47 140.14a41.23 41.23 0 017.16 9.55 41 41 0 017.16-9.55"
            data-name="16"
          ></path>
          <path
            d="M147.64 140.14a41.23 41.23 0 017.16 9.55 41.23 41.23 0 017.16-9.55"
            data-name="17"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#143554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M97.54 97.2L97.54 154.46"
        ></path>
        <path
          fill="none"
          stroke="#9dc9e4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M14 6.54v59.65a19.09 19.09 0 0019.1 19.08h0a19.07 19.07 0 0019.11-19h0A14.31 14.31 0 0037.91 52h0A11.93 11.93 0 0026 63.8h0a9.54 9.54 0 009.54 9.54h0"
        ></path>
        <path
          fill="none"
          stroke="#9dc9e4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M37.9 51.87h64.41A16.75 16.75 0 01119 68.57h0a16.74 16.74 0 01-16.7 16.7H33.13"
        ></path>
      </svg>
    </div>
  )
}

export default SvgFinishing
