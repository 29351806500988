import React from "react"
import styled from "styled-components"
import {
  headlineOne,
  bodyCopy,
  standardWrapper,
  colors,
  headlineThree,
} from "../Utilities"
import RenoProcessPhaseOne from "./RenoProcessPhaseOne"
import RenoProcessPhaseTwo from "./RenoProcessPhaseTwo"

const RenoProcessSection = styled.section`
  .wrapper {
    ${standardWrapper};
  }

  .renoTitle {
    width: 100%;
    text-align: center;

    h2 {
      ${headlineOne};
      color: ${colors.colorPrimary};
    }
  }

  .renoIntro {
    width: 100%;

    p {
      ${bodyCopy};
    }
  }

  .renoExtro {
    width: 100%;
    text-align: center;

    p {
      ${headlineThree};
      color: ${colors.colorPrimary};
      font-style: italic;
    }
  }
`

const RenoProcess = ({ title, intro, extro }) => {
  return (
    <RenoProcessSection>
      <div className="wrapper">
        <div className="renoTitle">
          <h2>{title}</h2>
        </div>
        <div
          className="renoIntro"
          dangerouslySetInnerHTML={{ __html: intro }}
        />
      </div>
      <RenoProcessPhaseOne />
      <RenoProcessPhaseTwo />
      <div className="wrapper">
        <div
          className="renoExtro"
          dangerouslySetInnerHTML={{ __html: extro }}
        />
      </div>
    </RenoProcessSection>
  )
}

export default RenoProcess
