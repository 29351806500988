import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import HeroImage from "../components/HeroImage"
import Intro from "../components/Intro"
import ImageBgBesideContent from "../components/ImageBgBesideContent"
import FullServiceRenos from "../components/FullServiceRenos"
import RenoProcess from "../components/RenoProcess"
import CalloutSection from "../components/CalloutSection"
import TestimonalSlider from "../components/TestimonalSlider"

const services = props => {
  const location = props.location.pathname
  const { acf } = props.data.page

  const metaTitle = acf._swb_theme_meta_title
  const metaDescription = acf._swb_theme_description
  const metaImage = acf._swb_theme_image.localFile.publicURL

  const heroImgFluid = acf._ikag_hero_image.localFile.childImageSharp.fluid
  const introTitle = acf._ikag_intro_title
  const introItalics = acf._ikag_intro_title_italic
  const introContent = acf._ikag_intro_content

  const iBBCimgFluid = acf._ikag_ibbc_image.localFile.childImageSharp.fluid
  const iBBCimgAlt = acf._ikag_ibbc_image.alt_text
  const iBBCBgImgFluid =
    acf._ikag_ibbc_background_image.localFile.childImageSharp.fluid
  const iBBCTitle = acf._ikag_ibbc_title
  const iBBCContent = acf._ikag_ibbc_content

  const fsrKitchen = acf._ikag_fsr_kitchens_images
  const fsrBathroom = acf._ikag_fsr_bathroom_images
  const fsrBasement = acf._ikag_fsr_basement_images
  const fsrFullInteriors = acf._ikag_fsr_full_interiors_images

  const renoProTitle = acf._ikag_rep_title
  const renoProIntro = acf._ikag_renp_intro
  const renoProExtro = acf._ikag_renp_extro

  const cosTitleTop = acf._ikag_cos_title_top
  const cosTitleBot = acf._ikag_cos_title_italic
  const cosBtnText = acf._ikag_cos_button_text
  const cosBtnLink = acf._ikag_cos_button_link.post_name

  const testDisplay = acf._ikag_testimonials_display

  return (
    <Layout location={location}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        metaImg={metaImage}
        location={props.location.pathname}
      />
      <HeroImage fluid={heroImgFluid} />
      <Intro title={introTitle} italic={introItalics} content={introContent} />
      <ImageBgBesideContent
        imgFluid={iBBCimgFluid}
        imgAlt={iBBCimgAlt}
        bgFluid={iBBCBgImgFluid}
        title={iBBCTitle}
        content={iBBCContent}
      />
      {/* <FullServiceRenos
        kitchens={fsrKitchen}
        bathroom={fsrBathroom}
        basement={fsrBasement}
        fullInterior={fsrFullInteriors}
      /> */}
      <RenoProcess
        title={renoProTitle}
        intro={renoProIntro}
        extro={renoProExtro}
      />

      <CalloutSection
        titleTop={cosTitleTop}
        titleBot={cosTitleBot}
        btnText={cosBtnText}
        btnLink={cosBtnLink}
      />
      {testDisplay === "yes" && <TestimonalSlider />}
    </Layout>
  )
}

export const homeQuery = graphql`
  query servicesPage($id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
        _ikag_hero_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }

        _ikag_intro_title
        _ikag_intro_title_italic
        _ikag_intro_content

        _ikag_ibbc_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }

        _ikag_ibbc_background_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }

        _ikag_ibbc_title
        _ikag_ibbc_content

        _ikag_fsr_kitchens_images {
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
        _ikag_fsr_bathroom_images {
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
        _ikag_fsr_basement_images {
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
        _ikag_fsr_full_interiors_images {
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }

        _ikag_rep_title
        _ikag_renp_intro
        _ikag_renp_extro

        _ikag_cos_title_top
        _ikag_cos_title_italic
        _ikag_cos_button_text
        _ikag_cos_button_link {
          post_name
        }

        _ikag_testimonials_display
      }
    }
  }
`

export default services
