import React from "react"

const SvgNumbers = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="176"
        height="161"
        viewBox="0 0 176 161"
      >
        <g
          stroke="#143554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path
            fill="none"
            d="M130.82 27.26L119.14 55.2v78.92a6.72 6.72 0 006.72 6.72h9.92a6.72 6.72 0 006.72-6.72h0V55.2z"
          ></path>
          <path fill="none" d="M128.87 33.79L132.76 33.79"></path>
          <path
            fill="#9dc9e4"
            d="M142.5 57.14L128.87 57.14 130.82 57.14 130.82 125.27 128.87 125.27 142.5 125.27 142.5 57.14z"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#143554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M33.5 53.25L39.34 53.25"
        ></path>
        <path
          fill="none"
          stroke="#143554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M33.5 123.32L33.5 20.16 64.64 20.16 64.64 88.28"
        ></path>
        <path
          fill="none"
          stroke="#143554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M33.5 76.61L43.24 76.61"
        ></path>
        <path
          fill="none"
          stroke="#143554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M33.5 99.96L39.34 99.96"
        ></path>
        <circle cx="49.07" cy="35.74" r="3.89" fill="#143554"></circle>
        <path
          fill="#143554"
          stroke="#143554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M106.74 100L88 78.55l-54.5 62.29h74zm-37.23 25.26L88 104.13l18.49 21.13z"
        ></path>
      </svg>
    </div>
  )
}

export default SvgNumbers
