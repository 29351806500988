import React from "react"

const SvgDoor = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="176"
        height="161"
        viewBox="0 0 176 161"
      >
        <path
          fill="none"
          stroke="#143554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M32.08 142.41L143.92 142.41"
        ></path>
        <path
          fill="#143554"
          stroke="#143554"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M54.05 18.59v123.82h68V18.59zm52 109.84H70v-22s6.69-4 18-4 18 4 18 4zm0-43.94s-6.69-4-18-4-18 4-18 4V36.56s6.69-4 18-4 18 4 18 4z"
        ></path>
        <circle
          cx="111.96"
          cy="94.48"
          r="3.99"
          fill="#9dc9e4"
          stroke="#9dc9e4"
          strokeMiterlimit="10"
        ></circle>
        <path
          fill="none"
          stroke="#9dc9e4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M112 94.48l-.77.39a6.22 6.22 0 01-6.21-.39h0a6.19 6.19 0 00-6.22-.39l-.77.39"
        ></path>
      </svg>
    </div>
  )
}

export default SvgDoor
