import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  opacity: ${props => (props.isActive ? "1" : "0")};
`

const SingleImg = styled.div`
  width: calc(50% - 1rem);
  margin: 0 0.5rem;
  @media (min-width: 768px) {
    width: calc(50% - 5rem);
    margin: 0 2.5rem;
  }
`

const FullServicesRenosImages = ({ images, active }) => {
  return (
    <ImagesContainer isActive={active}>
      {images.map((img, index) => {
        return (
          <SingleImg key={index}>
            <Img
              fluid={img.image.localFile.childImageSharp.fluid}
              alt={img.image.alt_text}
            />
          </SingleImg>
        )
      })}
    </ImagesContainer>
  )
}

export default FullServicesRenosImages
